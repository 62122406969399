<template>
    <v-row v-if="row">
        <v-col cols="12" md="6">
            <v-select dense v-model="province" :items="provinces" item-text="name" item-value="id"
                :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')" :loading="loadingData"
                label="Estado">
            </v-select>
        </v-col>
        <v-col cols="12" md="6">
            <v-select dense v-model="municipality" :items="municipalities" item-text="name" item-value="id"
                :disabled="!province ||saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                :loading="loadingData" label="Municipio">
            </v-select>
        </v-col>
    </v-row>
    <v-col cols="12" v-else>
        <v-row>
            <v-col cols="12" md="6">
                <v-select dense v-model="province" :items="provinces" item-text="name" item-value="id"
                    :disabled="saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Estado">
                </v-select>
            </v-col>
            <v-col cols="12" md="6">
                <v-select dense v-model="municipality" :items="municipalities" item-text="name" item-value="id"
                    :disabled="!province || saveChanges || (user.roles != 'ADMIN' && user.roles != 'COPARMEX')"
                    :loading="loadingData" label="Municipio">
                </v-select>
            </v-col>
        </v-row>
    </v-col>
</template>
<script>
import service from "@/providers/CitiesService";

export default {
    name: "ProvinceMunicipality",
    props: {
        entity: {
            type: Object,
            default: undefined
        },
        saveChanges: {
            type: Boolean,
            required: true,
            default: false,
        },
        loadingData: {
            type: Boolean,
            default: false,
        },
        row: {
            Boolean,
            default: true,
        }
    },
    data(){
        return {
            user: {},
            province: null,
            municipality: null,
            provinces: [],
            municipalities: [],
            first: true,
        }
    },
    methods: {
        loadData(){
            service.getAllRecords().then((resp) => {
                this.provinces = resp.value;
            }).catch((err) => {
                console.log(err)
            });
        }
    },
    watch: {
        entity(value){
            const d = value ?? this.entity;
            if (d && this.first) {
                this.province = d.city_id;
                this.municipality = d.municipality_id;
                this.first = false;
            }
        },
        province(value){
            this.municipalities = this.provinces.find(e=>e.id === value).municipalities;
            this.$emit('update-data', {
                province: value
            });
            return value;
        },
        municipality(value){
            this.$emit('update-data', {
                municipality: value
            });
            return value;
        }
    },
    mounted(){
        this.user = this.$store.getters.getUser;
        this.loadData();
    },
    destroyed(){
        this.first = true;
    }
}
</script>
